import { ELECTION_ORDER_BY_NAME } from "../../../auto/js/metadata/ElectionOrderBy"


export const electionColumns = [
    {title: "name", field: "name"},
    {title: "electionDate", field: "electionDate"}
]

export const getOrderBy = () => {
    return ELECTION_ORDER_BY_NAME
}
import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';

import { OPEN_VIEW_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadPollingCenterForm } from "../../forms/metadata/PollingCenterForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { showNotification } from "../../../../auto/js/utils";
import { VoterList } from "../../../../auto/js/lists/VoterList";
import { pollingCenterMetadataLoader } from "../../../../auto/js/metadata/PollingCenterMetadataLoader";

const fields = [
    {title: "Constituency", field: "constituency"},
    {title: "Polling Center", field: "pollingCenter"},
];

export const displayVoterList = (gui) => {
    const readVoterLis = (onFinish) => () => {
        let PollingCenterList = createTableComponent(fields);
        let uuid = uuidv4();
        return{
            uuid, view: () => <PollingCenterList key={uuid} localData={true} loadData={pollingCenterMetadataLoader.getPollingCenterList()} actions={getTableActions()} editable={getTableEditable()}/>
        };
    }
    gui.goTo(readVoterLis());
}


const getTableEditable = () => {
    let editables = {};
    return editables;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <PrintIcon/>,
            tooltip: t`Print`,
            onClick: (event, rowData) => {
                 openVoterRollPrintDialog(rowData.pollingCenter, rowData.pollingCenterId);
            }
        }
    )
    return actions;   
}

const openVoterRollPrintDialog = (pollingCenterName, pollingCenterId) => {
    loadVoterList(pollingCenterId).then((voterList) => {
        addImages(voterList).then(list => {
            const printable = {
                content:<VoterList list={list} pollingCenterName={pollingCenterName}/>,
                copyParentStyle:true
            }
            PRINT_EVENT.publish(printable);
        })
    });
}

const loadVoterList = async (pollingCenterId) => {
	return await rest.request(getServiceUri() + "custom/get-vital-record-list-by-polling-station-code/" + pollingCenterId, 'GET');
}

const addImages = async (DefaultRows) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        if (row.faceId != null) {
            const imageUrl = getServiceUri() + 'face/image/' + row.faceId + "/" + token;
            row.image = (imageUrl != null)?imageUrl:"/public/avatar.png";
            newRows.push(row);
        }
        else {
            row.image = "/public/avatar.png";
            newRows.push(row);
        }
    }
    return newRows;
}
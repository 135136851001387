import React, { useState } from "react";
import { FormLabel } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { t } from "../../../auto/js/services";
import { ImageViewer } from "../../../auto/js/widgets";
import { formatDate } from "../../../auto/js/utils";
import { formState } from "../../../auto/js/forms/FormState";

export const ViewPersonComponent = (props) => {

    return(
        <>
		<Person loadData={ async () => props.loadData()} disabledPhoto={props.disabledPhoto}/>
        </>
    )
}

class Person extends React.Component {
    constructor(props) {
		super(props);
		this.formRef = React.createRef()
		this.state = {
			data: undefined,
			loading: true,
			loadingMessage: ''
		}
        props.loadData().then((data) => {
            this.setState({ data: data, loading: false })
        });
	}

    componentDidUpdate(prevProps, prevState) {
        this.props.loadData().then((data) => {
            if (prevState.data != data)
                this.setState({ data: data, loading: false })
        });
    }
    
    render() {
        let fullname = '';
        let birthdate;
        if (this.state.data) {
            if (this.state.data.birthdate != null) {
                let year = this.state.data.birthdate[0];
                let month = (this.state.data.birthdate[1] < 10)? "0" + this.state.data.birthdate[1]:this.state.data.birthdate[1];
                let day = (this.state.data.birthdate[2] < 10)? "0" + this.state.data.birthdate[2]:this.state.data.birthdate[2];
                birthdate = day + "-" + month + "-" + year;
            } else
                birthdate = t`Unknown`;
            
            fullname += this.state.data.firstname ? this.state.data.firstname + ' ' : '';
            fullname += this.state.data.fourthname ? this.state.data.fourthname + ' ' : '';
        }
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div class="row rv5info">
            {(!this.props.disabledPhoto) &&
            <div class="col-md-2">
                <ImageViewer image={(this.state.data.photo)?this.state.data.photo:{x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true}} width={100} height={100} borderRadiusStyle='10px'/>  
            </div>}
             <div class="col-md-3"><div class="results" style={{paddingLeft:"0px", marginTop: '30px'}}>
                 <p style={{height: '2rem', marginBottom: '0', color: '#6b6b6b'}}>{t`Name`}</p>
                 <p style={{height: '2rem', marginBottom: '0', color: '#6b6b6b'}}>{t`Birth Date`}</p>
                 <p style={{height: '2rem', marginBottom: '0', color: '#6b6b6b'}}>{t`Identification Number`}</p>
             </div>
             </div>
             <div class="col-md-2">
                <div class="results" style={{paddingLeft:"0px", marginTop: '30px'}}>
                    <p style={{height: '2rem', marginBottom: '0'}}>{fullname}</p>
                    <p style={{height: '2rem', marginBottom: '0'}}>{birthdate}</p>
                    <p style={{height: '2rem', marginBottom: '0'}}>{this.state.data.id}</p>
                </div>
            </div>
            </div>:
                ''
            }
            </LoadingOverlay>
            </>
        )    
    }
}
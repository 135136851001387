import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_ADVANCED_SEARCH, OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { displayReadCivilRecordForm } from "../../../auto/js/forms/CivilRecordForm";
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/VitalRecordOrderBy";
import { civilRecordColumns, getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../main/js/lists/CivilRecordListCommon";
import Facets from "../widgets/Facets";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { globalState } from "../../../main/js/GlobalState";
import { showNotification } from "../utils";

const CivilRecordListPage = ({uuid, defaultfilter, searchData, gui}) => {
    const[selectAllError, setSelectAllError] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);

    let CivilRecordsList = createTableComponent(civilRecordColumns);

    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        else if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }
    if (advancedSearchData != searchData)
    setAdvancedSearchData(searchData);
    const buildData = async (query) => {
        let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
        let filter = query;
        let data;
        filter["orderBy"] = VITAL_RECORD_ORDER_BY_FIRSTNAME;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        filter["vital-record"] = {}
        if (globalState.getElection())
            filter["vital-record"]["electionId"] = globalState.getElection();
        let inactiveList = []
        let vitalRecordTags =[];
        inactiveList = [false]
        errorList.forEach(element => {
            vitalRecordTags.push( {content: element} )
        });
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter["vital-record"]["inactiveList"] =  inactiveList;
        filter["vital-record"]["vitalRecordTags"] =  vitalRecordTags;
        if (query.search && query.search!='') {
            pojoMetadata["vital-record"].columns.forEach(element => {
                if(element.type=='text'){
                    filter["vital-record"][element.key]= query.search;
                }
            });
            filter["and"] = false;
            filter.fuzziness = "AUTO";
        }
        return await getCivilRecordData(filter).then(response => {
            return filterData(response, token).then((data) => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const getCivilRecordData = async (filter) => {
        let searchEndpoint = (advancedSearchData)?"vital-record/advanced-search":"vital-record/search-by-active/";
        return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
    }
    
    const countData = async (filter) => {
        let countEndpoint = (advancedSearchData)?"vital-record/count/advanced-search":"vital-record/count/search-by-active/";
        return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
    }

    const getErrorCodeFacets = () => {
       let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
       	getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
            <CivilRecordsList key={uuid} loadData={async (query) => buildData(query)} editable={getTableEditable(gui)}  actions={getTableActions(gui)} />
        </div>
    )
}

export const displayCivilRecordsList = (gui, advancedSearchData, filter) => {
    const readCivilRecordsList = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () =>  <CivilRecordListPage uuid={uuid} gui={gui} defaultfilter={filter} searchData={advancedSearchData}/>
        };
    }
    gui.goTo(readCivilRecordsList())
}

export const displayCivilRecordAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`Voter Record`, onSubmit: (filter) => onAdvancedSearchSubmit(gui, filter)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (gui, filter) => {
   displayCivilRecordsList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
 	let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const filterData = async (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.birthdate
        if ( date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
        if (row.faceId != null) {
            const imageUrl = getServiceUri() + 'face/image/' + row.faceId + "/" + token;
            row.image = (imageUrl != null)?imageUrl:"/public/avatar.png";
            newRows.push(row);
        }
        else {
            row.image = "/public/avatar.png";
            newRows.push(row);
        }
    }
    return newRows;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`View`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadCivilRecordForm(() => displayCivilRecordsList(gui)), rowData.id);
                }
        }
    )
    return actions;
}

const getTableEditable = (gui) => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            return rest.purge('vital-record', rowData.id)
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayCivilRecordsList(gui));
    return editables;
}
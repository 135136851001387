import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import {createTableComponent} from "../../../../auto/js/widgets/TableComponent";
import {geoDataMetadataLoader} from "../../metadata/GeoDataMetadataLoader";
import { displayReadLocationForm } from "../../forms/metadata/LocationForm";
import { showNotification } from "../../../../auto/js/utils";
import { SimpleAutoCompleteInput } from "../../widgets/SimpleAutoCompleteInput";
import { pollingCenterMetadataLoader } from "../../metadata/PollingCenterMetadataLoader";

const fields = [
    {title: "country", field: "country", editable: 'never'},
    {title: "province", field: "province", editable: 'never'},
    {title: "zone", field: "zone", editable: 'never'},
    {title: "district", field: "district", editable: 'never'},
    {title: "island", field: "island", editable: 'never'},
    {title: "location", field: "location", editable: 'never'},
    {title: "pollingCenter", field: "pollingCenter", 
        editComponent: props => {
            const [value, setValue] = useState(props.value);

            const handleChange = (newValue) => {
		        if (newValue.key) {
                    props.onChange(newValue);
		            setValue(newValue);
		        }
   			}
            return (
                <SimpleAutoCompleteInput  
                    name={"pollingCenter"} 
                    options={() => pollingCenterMetadataLoader.getLevelChildsByIndex(1)} 
                    handleChange={(data) => handleChange(data)}  
                    defaultValue={value}
                    disabled={false}/>

            )
        },
        render: rowData => <>{(rowData.pollingCenter && rowData.pollingCenter != null)?rowData.pollingCenter.value:""}</>
    }
];

export const displayLocationList = (gui) => {
    const readLocationList = (onFinish) => () => {
        let LocationsList = createTableComponent(fields);
        let uuid = uuidv4();
        return {
            uuid, view: () => <LocationsList key={uuid} localData={true} loadData={geoDataMetadataLoader.getLocationList()} editable={getTableEditable(gui)} actions={getTableActions(gui)} />
        };
    }
    gui.goTo(readLocationList());
}

const getTableEditable = (gui) => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            let dto = form2dto(rowData);
            return rest.update('geo-data', dto)
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayLocationList(gui));
    editables.onRowUpdate = (newData, oldData) =>
        new Promise((resolve, reject) => {
            let dto = form2dto(newData);
            dto.disabled = false;
            dto["pollingCenterId"] = (newData.pollingCenter && newData.pollingCenter != null)?newData.pollingCenter.key:null;
            try {
                return rest.update('geo-data', dto).then((response) => {
                    if (response.status) {
                        showNotification(response.message?.split('Detail: ')[1], "error");
                        reject();
                    }
                    else {
                        showNotification(t`Linked Location to Polling Center`, "success");
                        geoDataMetadataLoader.load().then(() => resolve());
                    }
                })
            } catch (err) {
                alert(err);
            }
        }).then(() => displayLocationList(gui));
    return editables;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
        icon: () => <VisibilityIcon/>,
        tooltip: t`View`,
        onClick: (event, rowData) => {
            gui.goTo(displayReadLocationForm(),
            {
                "country": {key: rowData.countryId, value: rowData.country},
                "province": {key: rowData.provinceId, value: rowData.province},
                "zone": {key: rowData.zoneId, value: rowData.zone},
                "district": {key: rowData.districtId, value: rowData.district},
                "island": {key: rowData.islandId, value: rowData.island},
                "location": rowData.location,
                "id": rowData.locationId,
                "areaId": rowData.areaId
            }
            );
        }
        }
    );
    return actions;
}

const form2dto = (data) => {
	let updatedata = {};
	updatedata.id = ~~(data.locationId);
	updatedata.areaId = data.areaId;
	updatedata.name = data.location;
	updatedata.parentId = ~~(data.islandId);
	updatedata.type = 6;
	if(updatedata.areaId == "")
		updatedata.areaId = null;
	if(updatedata.name == "")
		updatedata.name = null;
    updatedata.disabled = true;
	return updatedata
}

import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH } from '../../../auto/js/events/Gui';
import { t } from "../../../auto/js/services/i18ndb";
import { AlertDialog } from "../../../auto/js/widgets";

import { saveElectionForm, buildElectionFormEmptyObject, electionFields } from "../../../main/js/forms/ElectionFormCommon";
import { displayElectionList } from "../lists/ElectionList";

export class ElectionFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteElectionForm(id);
		this.state.closeRequested(true);
	}

	render() {
	let NewElectionForm = createFormComponent(electionFields);

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<NewElectionForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => buildElectionFormEmptyObject(electionFields)} onSubmit={this.props.onSubmit} buttons={getButtons} />
		</>
		)
	}

}

export const displayNewElectionForm = (gui) => {
	const readNewElectionForm = (onFinish) => () => {
		let uuid = uuidv4();
		return {
		uuid, view: () => <ElectionFormComponent key={uuid} gui={gui} onSubmit={(onFinish)?(data) => save(data).then(()=>onFinish()):save}/>
		};
	}
	gui.goTo(readNewElectionForm(() => displayElectionList(gui)))
}

const save = async (formData) => {
	return saveElectionForm(formData)
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
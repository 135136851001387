import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PrintIcon from '@material-ui/icons/Print';

import { OPEN_VIEW_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadPollingCenterForm } from "../../forms/metadata/PollingCenterForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { showNotification } from "../../../../auto/js/utils";
import { VoterList } from "../../../../auto/js/lists/VoterList";
import { pollingCenterMetadataLoader } from "../../../../auto/js/metadata/PollingCenterMetadataLoader";

const fields = [
    {title: "Constituency", field: "constituency"},
    {title: "Polling Center", field: "pollingCenter"},
];

export const displayPollingCenterList = (gui) => {
    const readPollingCenterList = (onFinish) => () =>{
        let PollingCenterList = createTableComponent(fields);
        let uuid = uuidv4();
        return {
            uuid, view: () => <PollingCenterList key={uuid} localData={true} loadData={pollingCenterMetadataLoader.getPollingCenterList()} actions={getTableActions(gui)} editable={getTableEditable(gui)}/>
        };
    }
    gui.goTo(readPollingCenterList());
}


const getTableEditable = (gui) => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            return rest.delete('polling-center', rowData.id).then((response) =>
            {
                if (response.status != 200 || response.status != 201 || response.status != 204) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Polling Center`, "success");
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayPollingCenterList(gui));
    return editables;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadPollingCenterForm(() => displayPollingCenterList(gui)), {
                        "constituency": {key: rowData.constituencyId, value: rowData.constituency},
                        "pollingCenter": rowData.pollingCenter,
                        "id": rowData.pollingCenterId,
                        "areaId": rowData.areaId
                    });
                }
        }
    )
    
    return actions;   
}

const openVoterRollPrintDialog = (pollingCenterName, pollingCenterId) => {
    loadVoterList(pollingCenterId).then((voterList) => {
        addImages(voterList).then(list => {
            const printable = {
                content:<VoterList list={list} pollingCenterName={pollingCenterName}/>,
                copyParentStyle:true
            }
            PRINT_EVENT.publish(printable);
        })
    });
}

const loadVoterList = async (pollingCenterId) => {
	return await rest.request(getServiceUri() + "custom/get-vital-record-list-by-polling-station-code/" + pollingCenterId, 'GET');
}

const addImages = async (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        if (row.faceId != null && row.faceId != 0) {
            await rest.loadImage(getServiceUri() + 'face/image/' + row.faceId).then((image) => {
                var objectURL = URL.createObjectURL(image);
                row.image = (objectURL != null) ? objectURL : '/public/avatar.png';                  
            })
		} else
            row.image = "/public/avatar.png";
      newRows.push(row);
    }
    return newRows;
}
import { CachedImageLoader } from "../../auto/js/events/CachedImageLoader";
import { ELECTION_CHANGE_EVENT } from "./events/Event";

class GlobalState {
    constructor() {
        if (!!GlobalState.instance) {
            return GlobalState.instance;
        }

        GlobalState.instance = this;
        this.avatarImage = new CachedImageLoader("/public/avatar.png");
        this.election = undefined;
        ELECTION_CHANGE_EVENT.subscribe((el) => this.setElection(el));
        return this;
    }

    getAvatarImage () {
        return this.avatarImage;
    }

    setElection = (el) => {
        this.election = el;
    }
    getElection() {
        return this.election;
    }

}

export const globalState = new GlobalState();

import { rest } from "../../../auto/js/services/RestClient";

class PollingCenterMetadataLoader {

    constructor() {
        if (!!PollingCenterMetadataLoader.instance) {
            return PollingCenterMetadataLoader.instance;
    }
    PollingCenterMetadataLoader.instance = this;
    this.areas= {};
    this.children = {};
    this.locationsdata = [];
    this.maxAreaId = 0;
    return this;
}

	load = async () => {
		let filter = {};
		filter['polling-center'] = {disabled: false};
		filter.orderBy = null;
        this.areas= {};
		this.rootAreaIdList = [];
		return rest.search('polling-center', filter)
			.then(response => {
				response.forEach(element => {
					this.areas[element.areaId] = { "name": element.name, "id": element.id };
					if (element.type < 3 && !this.children[element.areaId] ) {
						this.children[element.areaId] = [];
					}
					if (element.type == 2) {
						this.rootAreaIdList.push(element.areaId);
						if (parseInt(element.areaId) > this.maxAreaId)
							this.maxAreaId = parseInt(element.areaId)
					}
					if (!this.isRootNode(element.areaId)) {
						let parentareaid = this.getParentAreaId(element.areaId);
						this.children[parentareaid]? this.children[parentareaid].push(element.areaId) :
						this.children[parentareaid] = [element.areaId];
					}
				});
			})
			.then(()=> {
			this.locationsdata = [];
			this.preparelocationsdata();
			});
	}

	preparelocationsdata = () => {
		var eldetails = [];
		const getLocationsFlat = (ellist, eldetail) => {
			ellist.forEach(el => {
			var elname = eldetail + "#" + this.areas[el].name + "::" + this.areas[el].id;
			if (Array.isArray(this.children[el]) && (this.children[el].length)) {
				getLocationsFlat(this.children[el], elname);
			}
			else {
				eldetails.push({"Hierarchy":elname, "Areaid": el});
			}
			});
			return eldetails;
		}
		this.rootAreaIdList.forEach(element => {
			if (this.children[element].length)
				eldetails = getLocationsFlat(this.children[element],
					this.areas[element].name + "::" + this.areas[element].id)
		});
		
		eldetails.forEach(el => {
			const ArrayData = el.Hierarchy.split("#");
			this.locationsdata.push({
				"constituency": ArrayData[0]?.split("::")[0],
			    "constituencyId": ArrayData[0]?.split("::")[1],
			    "polling_center": ArrayData[1]?.split("::")[0],
			    "polling_centerId": ArrayData[1]?.split("::")[1],
			    "areaId": el.Areaid
			})
		});
	}

	getParentAreaId = (areaId) => {
		return areaId.substring(0, areaId.lastIndexOf("."))
	}

	isRootNode = (areaId) => {
		return areaId.lastIndexOf(".") === -1;
	}

	getRootNodes = () => {
  		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === -1);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getChilds = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let result = Object.fromEntries(this.children[areaid].map(x => [this.areas[x].id, this.areas[x].name]));
		return result;
	}
	
    getAreaId = (id) => {
        return Object.keys(this.areas).find(key => this.areas[key].id == id);
    }
    
    getChildsByAreaId = (areaId) => {
		if (this.children[areaId])
			return Object.fromEntries(this.children[areaId].map(x => [this.areas[x].id, this.areas[x].name]));
		else
			return []
	}
    
    getArea = (areaId) => {
        return this.areas[areaId];
    }
    
    getAreaLevelName = (areaId) => {
		let areaComponents = areaId.split(".");
		switch (areaComponents.length) {
			case (1):
				return "constituency";
			case (2):
				return "polling_center";
		}
	}
	
	getLevelChildsByIndex = (index) => {
		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === index);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getConstituencyList = () => {
		let constituencyList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 1);
		areaids.forEach(element => {
			let constituency = this.getArea(element);
			constituencyList.push({
			    "constituency": constituency.name,
			    "constituencyId": constituency.id,
			    "areaId": element,
			})
		});
		return constituencyList;
	}


	getPollingCenterList = () => {
		let polling_centerList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 2);
		areaids.forEach(element => {
			let polling_center = this.getArea(element);
			let areaComponents = element.split(".");
			let constituencyAreaId = areaComponents[0];
			let constituency = this.getArea(constituencyAreaId);
			polling_centerList.push({
				"constituency": (constituency)?constituency.name:"",
			    "constituencyId": (constituency)?constituency.id:"",
			    "pollingCenter": polling_center.name,
			    "pollingCenterId": polling_center.id,
			    "areaId": element,
			})
		});
		return polling_centerList;
	}
    
    getGeoLevel = (id, level) => {
		let areaId = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let areaIds = Object.keys(this.areas).filter(key => key.split(".").length === level + 1 && key.startsWith(areaId))
		let result = Object.fromEntries(areaIds.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result; 
	}
	
	getNewAreaId = () => {
		this.maxAreaId = this.maxAreaId + 1;
		return this.maxAreaId;
	}

	incrementAreaIdFromParentId = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let nextAreaId = `${areaid}.0`;
		if (this.children[areaid].length) {
			let biggerAreaId = Math.max(...this.children[areaid].map(x => x.split(".").slice(-1)[0]));
			nextAreaId = `${areaid}.${biggerAreaId + 1}`;
		}
		return nextAreaId;	
	}

}

export const pollingCenterMetadataLoader = new PollingCenterMetadataLoader();

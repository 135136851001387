
import {displayCivilRecordAdvancedSearch, displayCivilRecordsList} from '../../../auto/js/lists/CivilRecordList'
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'
import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'
import {whoami} from '../../../auto/js/users/UserInfo';
import {displayLocationsList} from "../../../auto/js/lists/metadata/LocationsList";
import { displayNewElectionForm } from '../forms/NewElectionForm'
import { displayElectionList } from '../lists/ElectionList'
import { displayLocationList } from '../../../auto/js/lists/metadata/LocationList'
import { displayNewLocationForm } from '../../../auto/js/forms/metadata/LocationForm';
import { displayPollingCenterList } from '../lists/metadata/PollingCenterList';
import { displayNewPollingCenterForm } from '../forms/metadata/PollingCenterForm';
import { displayVoterRecordsWithoutAddress } from '../lists/VoterRecordListWithoutAddress';
import { displayBatchLogList } from '../lists/BatchLogList';
import { displayVerifyForm } from '../forms/VerifyForm';
import { displayVoterList } from '../lists/metadata/VoterList';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "books": {
				submenu: {
					"books": {
						options: {
							search: { label: "Voter Records", do: (gui) => displayCivilRecordsList(gui) },
                            noAddress: { label: "Voter Records Without Address", do: (gui) => displayVoterRecordsWithoutAddress(gui)},
                            advancedSearch: { label: "Advanced Search", do: (gui) => displayCivilRecordAdvancedSearch(gui)}
						},
						label: "Voter Records"
					}
				},
				label: "Books"
			}
        }),
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "elections": {
                submenu: {
                    "election": {
                        options: {
                            new: {label: "New", do: (gui) => displayNewElectionForm(gui)},
                            applications: { label: "Elections", do: (gui) => displayElectionList(gui)},
                            importList: { label: "Import Status", do: (gui) => displayBatchLogList(gui)},
                        },
                        label: "Election"
                    }
                },
                label: "Election"
            }
        
        }),
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "voterRoll": {
                submenu: {
                    "import": {
                        options: {
                            list: {label: "Voter List", do: (gui) => displayVoterList(gui)},
                            importVoter: { label: "Import Voter", do: (gui) => displayVerifyForm(gui)}
                        },
                        label: "Import Status"
                    }
                },
                label: "Voter Roll"
            }
        }),
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {
                    "translations": {
                        options: {
                            list: {label: "Translations", do: (gui) => displayTranslationsList(gui)},
                            new: {label: "New", do: (gui) => displayNewTranslationsForm(gui)},
                        },
                        label: "Translations"
                    },
                    "locations": {
                        options: {
                            list: {label: "Geo Data", do: (gui) => displayLocationList(gui)},
                        },
                        label: "Geo Data"
                    },
                    "polling-center": {
                        options: {
                            list: {label: "Polling Centers", do: (gui) => displayPollingCenterList(gui)},
                            new: {label: "New", do: (gui) => displayNewPollingCenterForm(gui)},
                        },
                        label: "Polling Center"
                    }  
                },
                label: "Configuration"
            }
        }),
    }
}

export const menuType = RIBBON_MENU;

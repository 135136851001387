import { rest } from "../../../auto/js/services";

export interface ElectionInfo {
	name: string,
}

var electionInfo: ElectionInfo;

export const getElectionInfo = 
	() => electionInfo;
		
export const loadElectionInfo = async (id: any) => {
	let info = await rest.read('election', id);
	electionInfo = {
		name: info.name,
	}
	return electionInfo;
}

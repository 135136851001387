import { rest } from "../services";

export class CachedImageLoader {
    constructor(url) {
        this.callbacks = [];
        this.image = undefined;
        this.url = url;
        this.state = 1; // not loaded
    }

    request = ( callback ) => {
        this.callbacks.push( callback );
        if (this.state == 3) { // loaded
            this.respondAll();
        } 
        else if (this.state == 1) {
            this.state = 2 // loading
            rest.loadImage(this.url).then(
                response => {
                    this.image = response;
                    this.respondAll(); 
                }
            );
        }
    }

    respondAll = () => {
        let n = this.callbacks.length;
        for (let i = 0; i < n; i++) {
            let callback = this.callbacks.shift();
            callback(this.image);
        }
    }
}
import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import Facets from "../../../auto/js/widgets/Facets";
import { Checkbox, FormControlLabel } from "@mui/material";
import { MTLB_STATUS_IMPORTING, MTLB_STATUS_READY } from "../../../auto/js/metadata/MtlbStatus";
import { globalState } from "../GlobalState";
import { showNotification } from "../../../auto/js/utils";

const batchLogColumns = [
    {title: "Minimum", field: "min"},
    {title: "Maximum", field: "max"},
    {title: "People count", field: "loaded"},
    {title: "Status", field: "mtlbStatus"},
]

const BatchLogPage = ({uuid}) => {
    const [importing, setImporting] = useState(false);
    const [ready, setReady] = useState(false);
    const[selectAllStatus, setSelectAllStatus] = useState(false);

    let BatchLogList = createTableComponent(batchLogColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            
            case ("importing"):
                setImporting(value.target.checked);
                break;
            case ("ready"):
                setReady(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setImporting(value.target.checked);
            	setReady(value.target.checked);
				break;
        }
    }

    const buildData = async (query) => {
        let filter = query;
        filter["orderBy"] = "ID";
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        filter["batch-log-document"] = {}
        if (globalState.getElection())
            filter["batch-log-document"]["electionId"] = parseInt(globalState.getElection());
        let mtlbStatusList = [];
        if (importing)
            mtlbStatusList.push(MTLB_STATUS_IMPORTING);
        if (ready)
            mtlbStatusList.push(MTLB_STATUS_READY);
        if (!importing && !ready)
            mtlbStatusList = [MTLB_STATUS_IMPORTING, MTLB_STATUS_READY]
        filter["batch-log-document"]["mtlbStatusList"] = mtlbStatusList;
        filter['and'] = true;
        if (query.search && query.search!='') {
            pojoMetadata["batch-log"].columns.forEach(element => {
                if(element.type=='text'){
                    filter["batch-log-document"][element.key]= query.search;
                }
            });
            filter["and"] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            return filterData(response).then((data) => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const getData = async (filter) => {
        return await rest.request(getServiceUri() + "batch-log/search-document/", "POST", filter);
    }
    
    const countData = async (filter) => {
        return await rest.request(getServiceUri() + "batch-log/count-document/", "POST", filter);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Batch Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "importing", value: importing }, { key: "ready", value: ready }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <BatchLogList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions()} />
        </div>
    )
}

export const displayBatchLogList = (gui) => {
    const readBatchLogList = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () =>  <BatchLogPage uuid={uuid} />
        };
    }
    gui.goTo(readBatchLogList())
}


const filterData = async (defaultRows) => {
    return defaultRows;
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <AssignmentReturnedIcon/>,
                tooltip: t`re-import`,
                onClick: (event, rowData) => {
                    let dto = {batchId: rowData.id, min: rowData.min, max: rowData.max, electionId: rowData.electionId};
                    rest.request(getServiceUri() + 'apply/re-import-batch', 'POST', dto).then(r => {
                        showNotification(t`Re-importing the selected batch`, "success");
                    });
                }
        }
    )
    return actions;
}
import React from "react";
import { v4 as uuidv4 } from 'uuid';

import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { rest } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";

import { electionFields } from "../../../main/js/forms/ElectionFormCommon"


export const displayReadElectionForm = (onFinish) => (id) => {
	let ElectionForm = createFormComponent(electionFields);
	let uuid = uuidv4();
	return {
		uuid,
		view: () => {
			return (
				<>
					<ElectionForm key={uuid} loadData={async () => loadFormData(id)} readOnly buttons={() => null}/>
				</>
			)
		}
	};
}

const loadFormData = async (id) => {
	return await rest.read('election', id).then(response => {
        return response;
	})
}
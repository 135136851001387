import { metadataLoader } from "../../auto/js/metadata"
import { geoDataMetadataLoader } from "../../auto/js/metadata/GeoDataMetadataLoader";
import { pollingCenterMetadataLoader } from "../../auto/js/metadata/PollingCenterMetadataLoader";
import { rest } from "../../auto/js/services";
import { loadElectionInfo } from "./election/ElectionInfo";
import { ELECTION_CHANGE_EVENT } from "./events/Event";

export const loadCountries = async () => {
    return metadataLoader.load("country")
}

export const loadGeoData = async () => {
    return geoDataMetadataLoader.load();
}

export const loadElection = async () => {
    return rest.readPreferences().then(response => {
        let el = response.find(l => l.key == "election")?.value;
        if (el != null) {
            ELECTION_CHANGE_EVENT.publish(el);
            loadElectionInfo(el);
        }
    })
}

export const loadPollingCenter = async () => {
    return pollingCenterMetadataLoader.load();
}

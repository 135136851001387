import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";
import { civilRecordFormfields } from "../../../main/js/forms/CivilRecordFormCommon"


import '../../css/printable.css';


export const displayReadCivilRecordForm = (onFinish) => (id) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let uuid = uuidv4();
	return {
		uuid,
		view: () => {
			return (
				<>
					<CivilRecordForm key={uuid} loadData={async () => loadFormData(id)} readOnly onSubmit={(onFinish)?(data) => saveAddress(data).then(()=>onFinish()):saveAddress} buttons={getButtons}/>
				</>
			)
		}
	};
}

const loadFormData = async (id) => {
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
            await rest.loadImage(getServiceUri() + 'face/image/' + response.faceId).then((image) => {
                var objectURL = URL.createObjectURL(image);
                form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (objectURL != null) ? objectURL : '/public/avatar.png', isEmpty: true };                  
            })
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}

const saveAddress = async (formData) => {
	let dto = {id: formData.id, address: formData.lastAddress}
	try {
		return rest.request(getServiceUri() + 'apply/update-voter-address', 'POST', dto).then(response => {
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
		})
    } catch (err) {
        alert(err);
    }
}
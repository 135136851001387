import React from "react";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { buildEmptyObject, createFormComponent } from "../../../auto/js/widgets/FormComponent";
import { OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { rest, t } from "../../../auto/js/services";
import { getServiceUri } from "../../../auto/js/metadata";

import { globalState } from "../GlobalState";
import { ViewPersonComponent } from "../widgets/ViewPersonComponent";
import { showNotification } from "../../../auto/js/utils";

const verifyFields = [
	{ name: "id", type: "text", x: 1, y: 2, layout: "col-md-12" },
];


let VerifyForm = createFormComponent(verifyFields);

class FormComponent extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			verifyResult: undefined
		}
    }

    verify = async (formData) => {
        let dto = form2dto(formData);
        try {
            return rest.request(getServiceUri() +  'apply/verify-voter', "POST", dto).then(
                response => {
                    this.setState({verifyResult: response })
                }
            )
        } catch (err) {
            alert(err);
        }
    }

    save = async (formData) => {
        let dto = form2dto(formData);
        dto.electionId = globalState.getElection();
        try {
            return rest.request(getServiceUri() +  'apply/create-voter', "POST", dto).then(
                response => {
                    showNotification(t`Imported Voter`, "success");
                    this.setState({verifyResult: response })
                }
            )
        } catch (err) {
            alert(err);
        }
    }

    render() {
        return (
            <>
            <div className='verification row'>
                <div className='col-md-6' style={{backgroundColor: 'white'}}>
                    <h2 style={{textAlign: 'center', marginBottom: '40px'}}>{t`Verification`}</h2>
                    <VerifyForm loadData={async () => buildEmptyObject(verifyFields)} onSubmit={this.verify} buttons={getButtons}/>
                </div>
            </div>
            {(this.state.verifyResult)?
            <div className='verification row'>
                <div className='col-md-10' style={{backgroundColor: 'white'}}>
                    <ViewPersonComponent loadData={async () => buildResultObject(this.state.verifyResult)}/>
                </div>
                <div className='col-md-2' style={{backgroundColor: 'white'}}>
                <button onClick={() => this.save(buildImportObject(this.state.verifyResult))} style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Import`} </button>
                </div>
            </div>
            :null}
            
            </> 
        )
    }
}

export const displayVerifyForm = (gui) => {
    const readVerifyForm = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent />
        };
    }
    gui.goTo(readVerifyForm());
}

const buildImportObject = (result) => {
    let face = null;
    if (result.face != null)
        face = "data:".concat("image/png", ";base64,", result.face);
    result['photo'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face != null)?false:true};
    
    return result;
}

const buildResultObject = (result) => {
    let face = null;
    if (result.face != null)
        face = "data:".concat("image/png", ";base64,", result.face);
    result['photo'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face != null)?false:true};
    return result;
}

const form2dto = (form) => {
    let dto = form;
    dto.vitalRecordId = form.id;
    return dto;
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Verify`} </button>
}

const getImportButton = () => {
	return <button style={{ minWidth: '5rem', margin: '40px auto auto auto' }} type="submit"> {t`Import`} </button>
}
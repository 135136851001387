import * as React from 'react';

import "../../css/printable.css"
const formatDate = (date) => {
	let d = new Date(date[0], date[1]-1, date[2]);
	return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
}

export class VoterList extends React.Component {
	constructor(props) {
		super(props);
		this.list = props.list;
	}

	voter = (data) => {
		let birthdate = data.birthdate? formatDate(data.birthdate):'Unknown';
		
		let fullname = '';
		fullname += data.firstname ? data.firstname + ' ' : '';
		fullname += data.secondname ? data.secondname + ' ' : '';
		fullname += data.fourthname ? data.fourthname + ' ' : '';
		return (
			<tr><td><img src={data.image} width="60px"/></td> <td> {data.id}</td> <td> {fullname}</td> <td> {birthdate}</td></tr>
		)
	}

	render() {
		let rows = [];
		this.list.forEach(element => {
			rows.push(this.voter(element));
		});
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();
		return (
			<table className="printable-list">
				<thead>
					<tr><b>Date:</b> {datetime}   <b>Center: </b>{this.props.pollingCenterName}</tr>
					<tr>
						<th>Face</th>
						<th>ID</th>
						<th>Name</th>
						<th>Date of Birth</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}
}